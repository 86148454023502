import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerForms() {
    var url = ConfigAPI.baseURL + "forms" + Session.getToken();
    return instance.get(url);
  },
  mostrarForms(id) {
    var url = ConfigAPI.baseURL + "forms/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarForms(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "forms" + Session.getToken();
    return instance.post(url,params);
  },
  editarForms(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "forms/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarForms(id) {
    var url = ConfigAPI.baseURL + "forms/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  editarFormsFields(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "forms/"+ data.id + "/fields" + Session.getToken();
    return instance.post(url,params);
  },  

  obtenerFormsDetail() {
    var url = ConfigAPI.baseURL + "forms-details" + Session.getToken();
    return instance.get(url);
  }, 
  filterFormsDetail(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "forms-details/filter/query" + Session.getToken();
    return instance.post(url, params);
  },    
  eliminarFormsDetail(id) {
    var url = ConfigAPI.baseURL + "forms-details/"+ id + Session.getToken();
    return instance.delete(url);
  },
  convertFormsDetail(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "forms-details/convert-potential/"+ data.id + Session.getToken();
    return instance.post(url,params);
  }
}

export default servicesAPI;
